export function assertNonNullish<TModel>(
  value: TModel,
  message: string
): asserts value is NonNullable<TModel> {
  if (value == null) {
    // return funnelError(message, 'error')
    throw new Error(message);
  }
}

export function assertString(
  value: string,
  message: string
): asserts value is string {
  if (value == null || typeof value !== 'string' || value.length === 0) {
    // return funnelError(message, 'error')
    throw new Error(message);
  }
}
